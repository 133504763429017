import React from 'react'

  const Iframe = ({iframe}) => {
    const iframeHtml = {
        __html: iframe
      }
  
    return (
        <div className="pt-5 clearFix">
          <div dangerouslySetInnerHTML={ iframeHtml } />
        </div>
      );
  };
 
export default Iframe