import React from "react"
import { useStaticQuery } from "gatsby"
import {Col, Button, Form, FormGroup, Label, Input, Card, CardBody } from 'reactstrap';


import Layout from "../../components/layout/layout"
import SEO from "../../components/seo/seo"
import Iframe from '../../util/iframe'
import './_contact.scss'

export const ContactDetails = () => {
  const DetailsQuery = useStaticQuery(graphql`
    query detailsQuery
      {
        markdownRemark(
          fileAbsolutePath: {
            regex: "/contact/content/markdown/contact-content.md$/"
          }
        ) {
          frontmatter {
            title
            companyName
            address
            telephone
            telephoneFormatted
            email
            image {
                publicURL
            }
            altText
          }
          html
        }
      }
`)
  const details = DetailsQuery.markdownRemark.frontmatter
  const html = DetailsQuery.markdownRemark.html

  const enquiryText = (
    <>
      <h4>Enquiries:</h4>
      <p>{ details.companyName }<br/>
      <div dangerouslySetInnerHTML={{ __html: details.address.split(", ").join(", <br/>") }}></div><br/>
      Telephone: <a href={`tel:+${ details.telephone }`}>{details.telephoneFormatted}</a><br/>
      Email: <a href={`mailto: ${details.email}`}>{details.email}</a></p>
    </>
  )

  const directionsText = (
    <>
      <h4>Directions:</h4>
      <div dangerouslySetInnerHTML={ {__html: html} } ></div>
    </>
  )
  
  return (
    <Card>
      <CardBody>
      <Col md={12} className="text-center pb-2"></Col>
      <div className="contactDetails pb-0">
        <img 
          src={details.image.publicURL} 
          alt={details.altText} 
          style={{ maxWidth: 500, float: 'right', padding: '0 0 10px 15px' }}
        />
        {enquiryText}
        {directionsText}
      </div>
      </CardBody>
    </Card>
  )
}

export default class Contact extends React.Component {
  render() {
    const iframe = '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2485.748717890971!2d-2.606689184339456!3d51.46276987962786!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48718dddb431a5e5%3A0x1ed7859023ea20bc!2s4%20Hillside%2C%20Bristol%20BS6%206JP!5e0!3m2!1sen!2suk!4v1569322421469!5m2!1sen!2suk" width="100%" height="450" frameborder="0" style="border:0;" allowfullscreen=""></iframe>';
    return (
      <div>
        <Layout pageTitle='Contact'>
          <SEO title="Contact" />
          <div>
            <ContactDetails></ContactDetails>
            <Iframe iframe={iframe} />
          </div>
        </Layout>
      </div>
    )
  }
}
